<template>
  <div>
    <div ref="placard" class="placard-container" :class="`placard${configData.posterType === 0 ? configData.posterTemplateType : ( configData.posterUrl ? 4 : 5 )}`">
      <img ref="placardBg" class="bg-img" :src="posterBgUrl" />
      <div class="inner" :class="`${configData.color === 1 ? 'dark' : 'light' }`">
        <template v-if="!configData.posterType">
          <!-- 校招官网 -->
          <template v-if="uccnInfo.sysType === 2">
            <!-- 1-企业全称；2-企业简称 -->
            <div v-if="[1, 2].includes(configData.posterCompanyType)" class="name-company">
              <div class="company">【{{ configData.posterCompanyType === 2 ? uccnInfo.companyAbbr : uccnInfo.companyName }}】期待你的加入</div>
            </div>
          </template>
          <div v-else-if="configData.posterNameFlag || configData.posterCompanyFlag" class="name-company">
            <div v-if="configData.posterNameFlag" class="name">我是【{{ userName }}】</div>
            <div v-if="configData.posterCompanyFlag" class="company">我在{{ uccnInfo.companyAbbr || uccnInfo.companyName }}帮你内部推荐</div>
          </div>
          <div class="context">
            <p v-html="configData.content" />
          </div>
        </template>
        <div class="custom-content" v-else v-html="configData.customContent"></div>
        <div v-if="!configData.posterUrl && [6, 7, 8].includes(configData.posterTemplateType)" class="qrcode">
          <template v-if="configData.posterTemplateType === 7">
            <div ref="qrImg" class="qr-img"></div>
            <p v-if="!configData.posterType" class="long-press">长按识别二维码查看</p>
            <p v-if="jobName" class="job-name">{{ jobName }}</p>
          </template>
          <template v-else>
            <p v-if="!configData.posterType" class="long-press">长按识别二维码查看</p>
            <div ref="qrImg" class="qr-img"></div>
            <p v-if="jobName" class="job-name">{{ jobName }}</p>
          </template>
        </div>
        <div v-else class="qrcode">
          <div ref="qrImg" class="qr-img"></div>
          <div class="qr-text">
            <p v-if="!configData.posterType" class="long-press">长按识别二维码查看</p>
            <p v-if="jobName" class="job-name">{{ jobName }}</p>
          </div>
        </div>
      </div>
    </div>
    <div ref="placardImg" class="placard-img">
      <img v-if="dataURL" :src="dataURL">
    </div>
  </div>
</template>

<script>
  import './index.scss'
  import QRCode from 'qrcodejs2'
  import html2canvas from 'html2canvas'
  import { mapGetters } from 'vuex'
  import { local } from '@/utils'

  export default {
    props: {
      /**
       * posterTemplateType 模版类型 1，2，3
       * content 文案
       * posterNameFlag 展示内推人姓名
       * posterCompanyFlag 展示内推人公司
       * jobName 职位名称
       * posterUrl 模版背景图地址
       * websiteUrl 内推官网地址
       */
      configData: Object,
      userName: {
        type: String,
        default: ''
      },
      jobName: {
        type: String,
        default: ''
      },
      jobId: {
        type: [String, Number],
        default: ''
      }
    },
    data() {
      return {
        // 海报底图上传文件id 测试环境和线上环境固定保持一致，修改底图时注意保持一致
        imgList: {
          1: { 
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/49a122aa52d64454c774f9c76b6d19bc.jpg',
            id: 138199
          },
          2: {
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/df54c0ae4a805f981176ba4dc29b00eb.jpg',
            id: 138195
          },
          3: {
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/df54c0ae4a805f981176ba4dc29b00eb.jpg',
            id: 138190
          },
          6: {
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/388f00677146631261a4328ad7aed2e1.jpg',
            id: 256842
          },
          7: {
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/6fe4f537fa4bcc9c527f670a57bf0796.jpg',
            id: 256843
          },
          8: {
            url: 'https://hy-awa-ats.oss-cn-hangzhou.aliyuncs.com/website/fb209f09056bfb95d0f12c78c88428db.jpg',
            id: 256844
          }
        },
        dataURL: '',
        loading: '',
        placardBgLoaded: false
      }
    },
    computed: {
      ...mapGetters({
        uccnInfo: 'uccnInfo'
      }),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || '';
      },
      posterBgUrl() {
        if(this.configData.posterType === 0 && this.imgList[this.configData.posterTemplateType]) {
          return `/api/file/download.json?id=${this.imgList[this.configData.posterTemplateType].id}`;
        } else if(this.configData.posterUrl && this.configData.posterType === 1) {
          return `/api/file/download.json?id=${this.configData.posterFileId}`;
        }
      }
    },
    mounted() {
      this.loading = this.$loading({
        target: this.$refs.placardImg,
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading'
      });
      let url =  this.jobId ? `${window.location.origin}/job/detail/${this.jobId}?websiteId=${this.uccnInfo.id}&personId=${this.personId}` : `${window.location.origin}/job/list?websiteId=${this.uccnInfo.id}&personId=${this.personId}`;
      this.createQrCode(url);
      if(this.posterBgUrl) {
        this.$refs.placardBg.onload = ()=> {
          this.createHtmlToImg();
        };
      } else {
        this.createHtmlToImg();
      }
    },
    methods: {
      createQrCode(url) {
        let $qrCodeDom = this.$refs.qrImg;
        $qrCodeDom.innerHTML = '';
        const viewportWidth = window.innerWidth; // 视口宽度
        new QRCode($qrCodeDom, {
          text: `${url}`,
          width: viewportWidth * 0.18,
          height: viewportWidth * 0.18
        });
      },
      DPR() { // 获取设备dpi
        if (window.devicePixelRatio && window.devicePixelRatio > 1) {
          return window.devicePixelRatio;
        }
        return 1;
      },
      createHtmlToImg() {
        setTimeout(() => {
          // 获取想要转换的 DOM 节点
          const dom = document.querySelector('.placard-container');
          const box = window.getComputedStyle(dom);
          // DOM 节点计算后宽高
          const width = parseInt(box.width, 10);
          const height = parseInt(box.height, 10);
          // 获取像素比
          const scaleBy = this.DPR();
          // 创建自定义 canvas 元素
          const canvas = document.createElement('canvas');
          // 设定 canvas 元素属性宽高为 DOM 节点宽高 * 像素比
          canvas.width = width * scaleBy;
          canvas.height = height * scaleBy;
          // 获取画笔
          const context = canvas.getContext('2d');
          // 将所有绘制内容放大像素比倍
          // context.scale(scaleBy, scaleBy);
          context.scale(1, 1);
          html2canvas(dom, {canvas, background: '#ffffff'}).then((imgDom) => {
            this.dataURL = imgDom.toDataURL();
            this.loading.close();
          })
        }, 1000);
    },

    }
  }
</script>
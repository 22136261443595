<template>
  <van-overlay :show="dialogData.isShow" class="h5-placard-modal">
    <van-swipe v-if="posterList && posterList.length" show-indicators>
      <van-swipe-item v-for="(item, index) in posterList" :key="index">
        <sub-placard v-if="item.id" :configData="item" :jobName="dialogData.jobName" :jobId="dialogData.jobId" />
      </van-swipe-item>
    </van-swipe>
    <sub-placard v-else-if="websiteInfo.id" :configData="websiteInfo" :userName="userName" :jobName="dialogData.jobName" :jobId="dialogData.jobId" />
    <div class="close-tip">
      <i @click="dialogData.isShow = false" class="h5pp h5pp-icon-close-empty"></i>
      <p>长按图片保存或转发</p>
    </div>
  </van-overlay>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Overlay, Swipe, SwipeItem } from 'vant'
  import { local } from '@/utils'
  import subPlacard from '@/components/pc/placard'

   export default {
    components: {
      [Overlay.name]: Overlay,
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem,
      subPlacard
    },
    props: {
      dialogData: Object
    },
    data() {
      return {
        posterList: [],
        websiteInfo: {},
        userName: '内推人'
      }
    },
    computed: {
      ...mapGetters(['uccnInfo', 'name']),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || local.get('oldPersonId') || '';
      }
    },
    created() {
      // 校招招聘官网（非内推）查询分享背景图列表
      if(this.uccnInfo.sysType === 2 && !this.uccnInfo.type) {
        this.queryPoserList();
      } else if(this.personId) {
        this.queryUserInfo();
      } else {
        this.queryWebsite();
      }
    },
    methods: {
      queryPoserList() {
        this.$http.get('/api/internal/recom/websit/school/poster/list.json', {params: {
          companyId: this.uccnInfo.companyId,
          status: 1,
        }}).then(data => {
          if(data) {
            this.posterList = data;
            let reg = new RegExp(/<strong class="email-tag" data-id="1"( style="")?>.*?<\/strong>/, 'g');
            this.posterList.forEach(item => {
              if(item.customContent) {
                if(this.userName) {
                  item.customContent = item.customContent.replace(reg, this.userName);
                } else {
                  item.customContent = item.customContent.replace(reg, '<strong class="email-tag" data-id="1"><\/strong>');
                }
              }
            });
          }
        });
      },
      queryUserInfo() {
        this.$http.get('/api/internal/recom/person/info.json', {params: {
          personId: this.personId
        } }).then(data => {
          if(data) {
            this.userName = data.name;
          }
          this.queryWebsite();
        });
      },
      queryWebsite() {
        this.$http.get('/api/internal/recom/websit/detail.json', {params: {
          websiteId: this.uccnInfo.id
        } }).then(data => {
          if(data) {
            this.websiteInfo = data;
            let reg = new RegExp(/<strong class="email-tag" data-id="1"( style="")?>.*?<\/strong>/, 'g');
            if(this.userName) {
              this.websiteInfo.customContent = this.websiteInfo.customContent.replace(reg, this.userName);
            } else {
              this.websiteInfo.customContent = this.websiteInfo.customContent.replace(reg, '<strong class="email-tag" data-id="1"><\/strong>');
            }
          }
        });
      }
    }
  }
</script>

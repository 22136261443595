<template>
  <van-action-sheet v-model="dialogData.isShow" cancel-text="取消" close-on-click-action>
    <ul class="action-sheet-box">
      <li v-for="(item, index) in dialogData.options" :key="index" @click="$emit('onSelect', item)">
        {{ item.name }}
      </li>
    </ul>
  </van-action-sheet>
</template>

<script>
  import './index.scss'
  import { ActionSheet } from 'vant'

  export default {
    components: {
      [ActionSheet.name]: ActionSheet
    },
    props: {
      dialogData: Object
    }
  }
</script>

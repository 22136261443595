<template>
  <div class="float-button">
    <div class="share-btn" @click="onShowShare"><i class="h5pp h5pp-icon-share3"></i>分享</div>
    <popup-select-action v-if="shareOptionObj.isShow" :dialogData="shareOptionObj" @onSelect="selectShareType"></popup-select-action>
    <popup-preview-placard v-if="previewPlycardObj.isShow" :dialogData="previewPlycardObj"></popup-preview-placard>
  </div>
</template>
<script>
  import './index.scss'
  import { mapGetters } from 'vuex'
  import { Toast, Dialog } from 'vant'
  import { local } from '@/utils'
  import popupSelectAction from '@/components/h5/selectAction'
  import popupPreviewPlacard from '@/components/h5/previewPlacard'

  export default {
    components: {
      [Dialog.name]: Dialog,
      popupSelectAction,
      popupPreviewPlacard
    },
    props: {
      jobId: {
        type: [String, Number],
        default: ''
      },
      jobName: {
        type: [String],
        default: ''
      }
    },
    computed: {
      ...mapGetters({
        uccnInfo: ['uccnInfo']
      }),
      personId() {
        return this.$route.query.personId || (JSON.parse(local.get('user') || '{}')).personId || local.get('oldPersonId') || '';
      },
      mobile() {
        return (JSON.parse(local.get('user') || '{}')).mobile || '';
      },
      pathName() {
        return this.$route.name;
      },
    },
    data() {
      return {
        shareOptionObj: {
          isShow: false,
          options: [{
            name: '分享到朋友圈（海报）',
            key: '1'
          },{
            name: '分享链接',
            key: '2'
          }]
        },
        url: '',
        previewPlycardObj: {
          isShow: false
        }
      }
    },
    created() {
      this.url = `${window.location.origin}/job/list?websiteId=${this.uccnInfo.id}&personId=${this.personId}`;
      if(this.jobId) {
        this.url = `${window.location.origin}/job/detail/${this.jobId}?websiteId=${this.uccnInfo.id}&personId=${this.personId}`;
      }
    },
    methods: {
      copyUrl() {
        this.$copyText(this.url).then(() => {
            Toast.success({
            message: '已复制到粘贴板'
          });
        }, () => {
          Toast.success({
            message: '浏览器版本过低，请升级浏览器'
          });
        });
      },
      onShowShare() {
        let options = [{
          name: '分享链接',
          key: '2'
        }];
        // 校招官网（sysType=2）和内推才展示分享到朋友圈
        if(this.uccnInfo.sysType === 2 || this.uccnInfo.type) {
          options.unshift({
            name: '分享到朋友圈（海报）',
            key: '1'
          });
        }
        this.shareOptionObj = {
          isShow: true,
          options: options
        };
      },
      selectShareType(obj) {
        switch(obj.key) {
          case '1':
            if(!this.mobile) {
              Dialog.confirm({
                title: '',
                message: '您还未登录，不会生成您的专属内推海报，确认继续分享吗？',
              }).then(() => {
                this.sharePlycard();
              });
            } else {
              this.sharePlycard();
            }
          break;
          case '2':
            if(!this.uccnInfo.type) {
              this.copyUrl();
            } else {
              if(!this.mobile) {
                Dialog.confirm({
                  title: '',
                  message: '您还未登录，不会生成您的专属内推链接，确认继续分享吗？',
                }).then(() => {
                  this.shareLink();
                });
              } else {
                this.shareLink();
              }
            }
          break;
        }
        this.shareOptionObj.isShow = false;
      },
      sharePlycard() {
        this.$utils.sendStat('0065', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId,
          jobId: this.jobId
        });
        this.previewPlycardObj = {
          isShow: true,
          jobName: this.jobName,
          jobId: this.jobId
        }
      },
      shareLink() {
        this.$utils.sendStat('0065', {
          webSiteId: this.uccnInfo.id,
          personId: this.personId,
          jobId: this.jobId
        });
        this.copyUrl();
      }
    }
  }
</script>
